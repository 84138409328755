@charset 'utf-8';
@import 'global';
@import 'settings';
@import 'util/util';
@import 'foundation';
@import 'nav';
@import 'frontpage';
@import 'matrix';
@import '_forms';
@import '_cookies';
@import '_news';

@include foundation-global-styles;
@include foundation-xy-grid-classes;
@include foundation-visibility-classes;
@include foundation-tabs;
@include foundation-dropdown-menu;

*,
*:before,
*:after {
	box-sizing: border-box;
}

html,
body {
	font-family: "Work Sans";
	margin: 0; padding: 0;
	height: 100%;
}

body {
	background-color: $color-background;

	&.open,
	&.no-scroll {
		overflow: hidden;
	}
}

#content {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100%;
	max-width: 1920px;
	width: 100%;
	margin: 0 auto;
	background: $color-background-content;
}

.flex-1 {
	flex: 1 0 auto;
}

.flex-align-center {
	display: flex;
	align-items: center;
	flex: 1 0 auto;
}

.page-full {
	padding-top: 144px;
	padding-bottom: 144px;

	@include breakpoint(medium down) {
		padding-top: 80px;
	}

	@include breakpoint(small down) {
		padding-top: 48px;
	}

	h1 {
		font-family: "Work Sans";	font-size: 45px;	font-weight: 300;	line-height: 54px;
		margin-top: 0;
	}
}

aside {
	background: rgba(#EDF1F7, 0.7);
	margin-right: 48px;
	padding: 2rem 0;
	margin-bottom: 16px;

	@include breakpoint(medium down) {
		margin-right: 0;
	}

	h4 {
		font-size: 16px;
		line-height: 19px;
		font-weight: 400;
		padding: 2px 2px 2px 44px;
		margin: 0 0 5px 0;
		text-transform: uppercase;
	}

	ul {
		margin: 0; padding: 0;
		list-style: none;

		li,
		.tabs-title {
			margin: 0; padding: 0;
			padding-top: 15px;

			&:first-of-type {
				padding-top: 0;
			}

			a {
				display: block;
				padding: 2px 2px 2px 2rem;
				border-left: 2px solid transparent;
				font-family: "Work Sans";
				font-size: 16px;
				font-weight: 300;
				color: #000;
				text-decoration: none;
				transition: all 400ms $transition;

				&.active,
				&:hover,
				&[aria-selected='true'],
				.is-active & {
					border-left-color: #919191;
					color: #919191;
					background: transparent;
				}

				&.active {
					font-weight: 600;
				}
			}

			ul.subnav {
				width: calc(100% - 4rem);
				margin-left: 2rem;
				background-color: rgba(#E4EAF2, 0.88);
				padding: 0.75rem 0;
				margin-top: 0.5rem;

				li {
					padding: 0;
					a {
						font-weight: 400;
						opacity: 0.8;
						padding: 0.3rem 0 0.3rem 1.25rem;
						font-size: rem-calc(14);

						&:hover {
							opacity: 1;
						}
					}
				}
			}
		}
	}
}

.matrix {
	font-size: 18px;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: "Work Sans";
		font-weight: 300;
	}

	p, ul {
		font-weight: 300;
		line-height: 1.7;
		margin-bottom: 36px;
	}
}

.youtube {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	width: 100%;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.map {
	padding-bottom: 56.25%;
	position: relative;
}

.width-40 {
	width: 40%;
}

.width-60 {
	width: 60%;
}

.width-100 {
	width: 100%;

	&.align-left,
	&.align-right {
		margin: 1rem auto 2rem auto;
	}
}

.width-40,
.width-60,
.width-100 {
	display: inline-block;

	@include breakpoint(small down) {
		width: 100%;
	}
}


.align-right {
	float: right;
	margin: 1rem 0 1rem 1rem;
}

.align-left {
	float: left;
	margin: 1rem 1rem 1rem 0;
}

.align-center {
	display: block;
	margin: 1rem auto 2rem auto;
}

blockquote {
	position: relative;
	margin: 0;
	padding: 30px 20px 60px 20px;
	color: $color-akastor-blue;
	font-size: 22px;
	text-align: center;
	margin-bottom: 40px;
	font-weight: 300;

	&:after {
		content: '';
		width: 100px;
		height: 1px;
		position: absolute;
		left: 50%;
		bottom: 0;
		margin-left: -50px;
		background: $color-akastor-blue;
	}
}

.matrix table {
	background: #fff;
	width: 100%;
	font-size: 12px;
	border-collapse: collapse;

	@include breakpoint(medium down) {
		white-space: pre;
	}

	tr:first-child {
		background: $color-akastor-blue;

		td {
			color: #fff;
			font-size: 14px;
			font-weight: 600;
			border: 1px solid $color-akastor-blue;
			white-space: nowrap;
		}
	}

	td {
		padding: 5px 10px;
		border: 1px solid rgba(#919191, 0.3);
		
		p {
			margin: 0;
			font-weight: inherit;
			font-size: inherit;
		}
	}
}

.table-wrap {
	width: 100%;
	overflow: auto;
	margin-bottom: 2rem;
}

.table-swipe {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding-bottom: 10px;
	user-select: none;

	i {
		font-size: 26px;
		color: $color-akastor-blue;
	}
}

*::selection {
	background: $color-akastor-blue;
	color: #fff;
}

.tabs-title {
	float: none;
}
.tabs-panel {
	padding: 0;
}

.people.number-3 {
	@include breakpoint(large up) {
		margin-right: -33%;
	}
}
.person {
	display: flex;
	flex-direction: column;

	&:hover {
		cursor: pointer;
	}

	background: #fff;
	margin-bottom: 2rem;

	img {
		width: 100%;
		height: auto;
	}

	.desc {
		display: none;
	}

	.info {
		display: flex;
		flex-direction: column;
		flex: 1 0 auto;
		padding: 32px;
	}

	.more {
		color: $color-akastor-blue;
	}

	h3 {
		font-weight: 400;
		margin: 0;
	}

	h4 {
		margin: 0 0 1.5rem 0;
	}
}

.files {
	margin-top: 48px;

	&.full-width {
		@include breakpoint(large) {
			margin-right: -33%;
		}
	}

	span {
		background: $color-akastor-blue;
		color: #fff;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 600;
		padding: 2px 5px;
		display: inline-block;
		margin-right: 10px;
	}

	ul {
		margin: 0; padding: 0;
		list-style: none;
	}

	.tabs-wrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		@include breakpoint(small down) {
			flex-direction: column;
		}

		h2 {
			text-transform: uppercase;
			margin: 0;
			font-size: 24px;
			font-weight: 300;
			line-height: 28px;

			@include breakpoint(small down) {
				margin-bottom: 2rem;
				align-self: flex-start;
			}
		}

		.tabs {
			border: 0;
			background: transparent;

			@include breakpoint(small down) {
				widtH: 100%;

				li {
					width: 100%;
				}
			}

			> li {
				float: left;
				margin-left: 1px;

				&:first-of-type {
					margin-left: 0;
				}

				&.is-active {
					a {
						background: #fff;
						color: #919191;
						font-weight: 600;
					}
				}

				a {
					background: rgba(237,241,247,0.7);
					padding: 20px;
					text-decoration: none;
					color: #000;

					&:focus {
						outline: none;
					}
				}
			}
		}
	}

	.tabs-content {
		background: #fff;
		border: none;
		padding: 48px;

		@include breakpoint(medium down) {
			padding: 18px;
		}

		li {
			margin-bottom: 15px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.dropdown.menu {

		> li {
			&.is-dropdown-submenu-parent {
				> a {
					display: block;
					padding: 14px;

					&:after {
						display: none;
					}
				}
			}

			&.is-dropdown-submenu-item {
				&:hover {
					background: rgba(#000, 0.3);
				}
			}
		}
	}
}

.investors {
	background: #F0F3F8;
	padding: 96px 0;

	@include breakpoint(medium down) {
		padding: 2rem 0;
	}

	.ticker {
		background-color: $color-akastor-blue;
		background-image: url(/dist/images/logo-symbol.svg);
		background-size: 400px;
		background-repeat: no-repeat;
		background-position: top 40px right 0;
		color: #fff;

		@include breakpoint(medium) {
			margin-bottom: 38px;
		}

		.last,
		small {
			color: inherit;
		}
	}

	.investor-children {
		display: flex;
		align-items: center;
		border: 1px solid #F0F3F8;
		overflow: hidden;

		.cell {
			border-bottom: 1px solid #e3e6ea;

			&:nth-last-child(2),
			&:last-child {
				border-bottom: none;
			}

			&:nth-child(odd) {
				border-right: 1px solid #e3e6ea;
			}

			&:nth-child(even) a {
				padding-left: 38px;
			}

			@include breakpoint(small down) {
				border-right: none !important;
				border-bottom: 1px solid #e3e6ea !important;
			}

			a {
				color: #000;
				text-decoration: none;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 38px 38px 38px 0;
				background: transparent;
				transition: all 400ms $transition;
				font-size: 16px;
				font-weight: 300;
				line-height: 19px;

				&:hover i {
					right: -16px;
				}

				i {
					color: $color-akastor-blue;
					position: relative;
					transition: 400ms $transition;
					right: 0;
				}

				@include breakpoint(medium down) {
					padding: 38px;
				}
			}
		}
	}
}

.news-line {
	background: #fff;
	padding: 35px 45px;
	margin-bottom: 38px;

	h3 {

		a {
			text-decoration: none;
		}
	}

	.meta {
		display: flex;
		flex-direction: row;
		align-items: center;
		font-size: 14px;
		font-weight: 300;
		flex-wrap: wrap;

		i {
			margin-right: 5px;
			opacity: 0.5;
			font-size: 13px;

			&:last-of-type {
				margin-left: 5px;
			}
		}
	}
}

.news-full {
	font-size: 18px;

	h1 {
		font-family: "Work Sans";
		font-size: 45px;
		font-weight: 300;
		line-height: 54px;
		margin-top: 0;
	}

	p {
		font-weight: 300;
		line-height: 1.7;
		margin-bottom: 36px;
	}
}

.pagination {
	display: flex;
	justify-content: space-around;
	margin-top: 40px;
	margin-bottom: 40px;

	a {
		color: inherit;
		font-weight: bold;
		text-decoration: none;
		border-bottom: 2px solid $color-akastor-blue;

		&.disabled {
			opacity: 0.5;
		}
	}

	.no-border {
		border: none;
	}

	.pages a {
		font-weight: 600;
		text-decoration: none;
		padding: 0 2px;

		&.current {
			color: $color-akastor-blue;
		}
	}
}

.investments {
	.tabs-title a {
		font-size: 16px;
	}

	.table-wrap:last-of-type {
		margin-bottom: 0;
	}

	table {
		font-size: 16px;
		white-space: normal;

		tr:first-child td {
			font-size: inherit !important;
			border-right: 1px solid rgba(#000, 0.1);
			white-space: nowrap;

			&:first-of-type {
				min-width: 230px;
			}
		}

		tr {
			background: #F6F8FB;

			&.active {
				background: #fff;
			}
		}

		td {
			font-size: inherit;
			padding: 20px;

			&:first-of-type {
				border-left: none;
				@include breakpoint(medium down) {
					border-right: none;
				}
			}

			&:last-of-type {
				border-right: none;
			}

			&.text-align-right {
				text-align: right;
			}
		}
	}

	.trigger {
		user-select: none;
		font-weight: 300;

		td:first-of-type {
			color: $color-akastor-blue;
			font-weight: 600;
		}

		@include breakpoint(large up) {
			td:first-of-type {
				min-width: 230px;
			}
		}

		&:hover {
			cursor: pointer;
		}

		&.active {
			td:first-of-type {
				color: #919191;
			}

			td {
				color: #000;
			}

			i {
				transform: rotate(180deg);
			}
		}

		div {
			display: flex;
			align-items: center;
			flex-direction: row;
		}
	}

	tr.info {
		display: none;

		strong {
			font-weight: 600;
		}

		.cell {
			p:first-of-type {
				margin-top: 0;
			}

			img {
				margin: 0 auto;
				max-width: 90%;
			}
		}

		&.active {
			display: table-row;
		}

		td {
			padding: 40px;
		}
	}
}

.person-modal {
	display: none;
	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	align-items: center;
	justify-content: center;
	z-index: 1000;
	overflow-y: auto;

	&.active {
		display: flex;

		@include breakpoint(small down) {
			display: block;

			.grid-x {
				width: 100%;
				height: 100vh;
				max-width: 100%;
				max-height: 100vh;
				overflow-y: auto;
			}
		}
	}

	.person-overlay {
		z-index: -1;
		background: rgba(#000, 0.3);
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;

		&:hover {
			cursor: pointer;
		}
	}

	.grid-x {
		position: relative;
		background: #fff;
		width: 1200px;
		max-height: 90vh;
		max-width: 90%;
		box-shadow: 0 2px 68px 0 rgba(0,0,0,0.19);
		overflow-y: auto;

		.background {
			background-position: center center;
			background-size: cover;
		}

		.wrapper {
			padding: 60px;

			@include breakpoint(small down) {
				padding: 25px;
				padding-top: 60px;
			}

			h3 {
				font-size: 45px;
				font-weight: 300;
				line-height: 54px;
				margin-top: 0;
				margin-bottom: 0;

				@include breakpoint(medium down) {
					font-size: 38px;
				}

				@include breakpoint(small down) {
					font-size: 32px;
				}
			}

			h4 {
				font-size: 20px;
				font-weight: 300;
				line-height: 54px;
				margin: 0;
				margin-bottom: 30px;
			}

			.contact {
				background: #F6F8FB;
				padding: 18px 28px;
				margin-bottom: 30px;
				font-size: 18px;
				font-weight: 300;
				line-height: 32px;

				a {
					word-break: break-all;
				}
			}

			p {
				font-size: 18px;
				font-weight: 300;
				line-height: 30px;
			}

			.more,
			.flex-1 {
				display: none;
			}
		}
	}

	.person-modal-close {
		position: absolute;
		top: 23px; right: 23px;
		border: 1px solid #E0E0E0;
		border-radius: 100%;
		width: 43px; height: 43px;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover {
			cursor: pointer;
		}
	}
}

article.light {
	opacity: 0.5;
}

article.calendar-line {
	font-size: 1rem;
}

dl.financial-calendar {
	dt, dd {
		font-size: 1rem;
		margin: 0;
		padding: 0;

		&.light {
			opacity: 0.55;
		}

		&:last-of-type {
			border: none;
		}
	}

	dt {
		font-size: rem-calc(14);
		font-weight: 600;
		color: rgba(#000, 0.5);
		margin-bottom: 0.15rem;
	}

	dd {
		margin: 0 0 1.25rem 0;
		padding:0 0 1.25rem 0;
		border-bottom: 1px solid rgba(#000, 0.08);
	}
}

.vi-logo {
	text-align: center;
	padding: 0 15px;

	&:hover {
		cursor: pointer;
	}

	a {
		display: block;
		font-size: 14px;
		text-decoration: none;
		color: inherit;
	}

	img {
		width: 100%;
		max-width: 60px;
		display: block;
		margin: 0 auto;
	}
}

.people-page{
	h4{
		font-size: 20px;
		font-weight: 300;
		line-height: 54px;
		margin: 0 0 30px;
	}
	h3{
		font-size: 45px;
		font-weight: 300;
		line-height: 54px;
		margin-top: 0;
		margin-bottom: 0;
	}
	p{
		font-size: 18px;
		font-weight: 300;
		line-height: 30px;
	}
}
.search-results li{
	padding: 5px;
	a{
		color: black;
		font-size: 16px;
	}
}

main {
	overflow: hidden;
}

.subscribe-box {

	.subscribe-container{
		padding: 0 40px;
	}

	form{
		width: 100%;
		margin-top: 20px;
		input[type="email"]{
			padding: 7px;
		}
		button{
			margin-top: 6px;
		}
		.g-recaptcha{
			margin-top: 8px;
			transform:scale(0.77);
			-webkit-transform:scale(0.74);
			transform-origin:0 0;
			-webkit-transform-origin:0 0;
			margin-bottom: 0;
		}
	}
	h4{
		padding: 0;
		margin: 0;
	}
	#subscribe-form-field-errors{
		display: none;
		p{
			font-size: 10px;
			padding: 4px;
			margin: 5px 0 0;
			border: 1px solid red;
		}
	}
	#subscribe-ok{
		display: none;
	}
	#subscribe-failed{
		display: none;
		p{
			color: red;
		}
	}
}

.news-sidebar {
	order: 1;
}

.news-content {
	order: 2;
}

@include breakpoint(medium down) {
	.news-sidebar {
		order: 3;
	}
}

.page_content .hugin ul li {
	line-height: inherit !important;
	font-size: inherit !important;
}
