nav {
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	background-color: #fff;
	z-index: 1000;

	* {
		user-select: none;
	}

	#menu {
		height: $nav-height;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		a {
			display: inline-block;

			&.logo img {
				width: 180px;
				max-width: 100%;
			}
		}

		.menu-left,
		.menu-right {
			display: flex;
			align-items: center;
			height: 100%;

			> a,
			> ul {
				display: flex;
				align-items: center;
				height: 100%;

				> li {
					display: flex;
					align-items: center;
					height: 100%;

					margin-left: 20px;
					border-bottom: 2px solid transparent;
					border-top: 2px solid transparent;
					transition: border 400ms $transition;
					font-weight: 300;

					&:hover,
					&.active {
						border-bottom: 2px solid #919191;

						@include breakpoint(medium down) {
							border-bottom: 1px solid rgba(#fff, 0.1);
						}
					}

					@include breakpoint(large up) {
						&.active {
							color: #919191;
							font-weight: 600;
						}
					}

					> a {
						display: flex;
						align-items: center;
						height: 100%;

						color: inherit;
						font-size: 18px;
						font-weight: inherit;
						line-height: 21px;
						text-decoration: none;
						text-transform: uppercase;
					}
				}
			}
		}

		.menu-right {
			ul {
				margin: 0; padding: 0;

				&.sub-menu {
					display: none;

					ul.sub-menu-level-2 {
						width: calc(100% - 50px);
						margin-left: 25px;
						background-color: rgba(#000, 0.08);
						padding: 0.9rem 0;
						margin-top: -0.15rem;
						margin-bottom: 1.15rem;
		
						li {
							padding: 0;
							a {
								font-weight: 400;
								opacity: 0.8;
								padding: 0.35rem 1.25rem;
								font-size: rem-calc(14);
							}
						}
					}
				}

				li {
					display: inline-block;
				}
				form{
					display: block;
					height: 100%;
				}
				input[type="search"]{
					width: 25px;
					margin-top: 26px;
					height: 30px;
					background-image: url('/dist/images/search.svg');
					background-position: 5px 8px;
					background-repeat: no-repeat;
					-webkit-background-size: 15px;
					background-size: 15px;
					border: 0;
					-webkit-transition: width 0.4s ease-in-out;
					transition: width 0.4s ease-in-out;
					padding-left: 27px;
				}
			}

			> ul {
				input[type="checkbox"],
				.submenu-trigger {
					display: none;
					width: 100%;
				}

				@include breakpoint(large up){
					input[type="search"]:hover{
						width: 125px;
					}
					input[type="search"]:focus{
						width: 125px;
					}
				}

				@include breakpoint(medium down) {

					.open & {
						right: 0;
					}

					position: fixed;
					top: $nav-height; right: -300px; bottom: 0;
					width: 300px;
					background: $color-akastor-blue;
					display: block;
					overflow-y: auto;
					transition: all 400ms $transition;
					z-index: 1000;

					input[type="checkbox"]:checked + .submenu-trigger + .sub-menu {
						display: block;
					}

					input[type="search"]{
						width: 100%;
						margin: 13px 25px;
						height: 40px;
						background-position: 5px 12px;
						background-color: transparent;
						background-image: url('/dist/images/search-white.svg');
						color: white;
					}
					input[type="search"]::-webkit-input-placeholder {
						color: white;
					}
					input[type="search"]::placeholder {
						color: white;
					}
					input[type="search"]:-ms-input-placeholder {
						color: white;
					}

					input[type="checkbox"]:checked + .submenu-trigger span {
						transform: rotate(225deg);
					}

					> li {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						flex-wrap: wrap;
						height: auto;
						margin: 0;
						background: transparent;
						border-top: none;
						border-bottom: 1px solid rgba(#fff, 0.1);

						&:hover {
							border-bottom: 1px solid rgba(#fff, 0.1);
						}

						.submenu-trigger {
							display: flex;
							height: 100%;
							width: 50px;
							align-items: center;
							justify-content: center;
							padding: 10px 25px 10px 10px;

							span {
								display: block;
								width: 15px; height: 15px;
								border-right: 2px solid #fff;
								border-bottom: 2px solid #fff;
								transform: rotate(45deg);
								transform-origin: 10px 10px;
								transition: all 400ms $transition;
							}

							&:hover {
								cursor: pointer;
							}
						}

						a {
							color: #fff;
							padding: 13px 25px;
							flex: 1 0 auto;
						}

						.sub-menu {
							background-color: rgba(#000, 0.1);
							width: 100%;

							li {
								width: 100%;
							}

							a {
								text-decoration: none;
								display: block;

								&.active {
									font-weight: 600;
								}
							}
						}
					}
				}
			}
		}
	}
}

footer {
	background: #fff;
	padding: 117px 0;
	font-size: 16px;
	font-weight: 300;

	@include breakpoint(medium down) {
		padding: 80px 0;
		text-align: center;
	}

	@include breakpoint(small down) {
		padding: 60px 0;
	}

	strong {
		font-weight: 600;
	}

	.logo {
		width: 180px;
		max-width: 100%;

		@include breakpoint(medium down) {
			padding-bottom: 30px;
			margin: 0 auto;
		}
	}

	.footer-links {
		border-top: 1px solid #EEEEEE;
		padding-top: 40px;
		margin-top: 30px;

		a {
			display: inline-block;
			margin-right: 16px;

			font-size: 14px;
			text-decoration: none;
			color: #000;
			border-bottom: 2px solid transparent;
			transition: all 400ms $transition;

			&:last-of-type {
				margin-right: 0;
			}

			&:hover {
				border-bottom: 2px solid rgba(#000, 0.7);
			}

			@include breakpoint(small down) {
				margin-bottom: 20px;
			}
		}
	}
}

.mobile-menu {
	display: none;

	&:hover {
		cursor: pointer;
	}
	@include breakpoint(medium down) {
		display: block;
	}

	span {
		display: block;
		position: relative;
		margin: 20px 10px;
		width: 40px;
		height: 2px;
		border-radius: 3px;
		background: #919ca1;
		transition: all 400ms $transition;

		&:after,
		&:before {
			content: "";
			width: inherit;
			height: inherit;
			position: absolute;
			transform: rotate(0);
			transition: all 400ms $transition;
			background: #919ca1;
			border-radius: inherit;
		}

		&:before {
			top: -12px;
		}

		&:after {
			bottom: -12px;
		}

		.open & {
			background: transparent;

			&:before {
				transform: rotate(135deg);
				top: 0;
			}

			&:after {
				bottom: 0;
				transform: rotate(-135deg);
			}
		}
	}
}

@include breakpoint(medium down) {
	.overlay {
		position: fixed;
		display: none;
		top: $nav-height; right: 0; bottom: 0; left: 0;
		z-index: 100;
		background: rgba(#000, 0.25);
		.open & {
			display: block;
		}
	}
}
