#search-input-big{
  background-image: url('/dist/images/search.svg');
  background-position: 15px 15px;
  background-repeat: no-repeat;
  -webkit-background-size: 20px;
  background-size: 20px;
  border: 0;
  padding: 15px 15px 15px 45px;
  width: 100%;
  -webkit-appearance: none;
}
