$cookie-color: red; //set to site color
$light-grey: rgba(0,0,0,0.3);
$black: #000;

/* bcc reset */
.bcc-container * {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  line-height: 1;
  box-sizing: border-box;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
}

.elc-hidden {
  display: none !important;
}

.bcc-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 0;
  display: none;
  font-size: 16px;
  font-weight: normal;
  color: $black;
  padding: 1rem;
  box-sizing: border-box;
  z-index: 99999;
  display: flex;

  .bcc-background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.4);
    z-index: 0;
  }
}

.bcc-page {
  display: none;
}

.bcc-page-active {
  display: block;
}

/* craft elc specific styles */
.elc-small .bcc-window .bcc-page-01 {
  display: block !important;
}

.elc-fullwidth .bcc-window .bcc-page-02 {
  display: block !important;
}

.bcc-page-content {
  max-height: calc(100vh - 2rem);
  display: flex;
  flex-direction: column;

  @include breakpoint(small down) {
    max-height: 80vh;
  }
}

.bcc-window {
  width: 100%;
  max-width: 800px;
  background: #F8F8F8;
  box-shadow: 0 10px 60px 0 rgba(0,0,0,0.15);
  position: relative;
  z-index: 1;
}


.bcc-window-header {
  display: flex;
  padding: 2.5rem 3rem;
  background-color: #fff;
  box-shadow: 0 1px 0 0 rgba(0,0,0,0.08);
  align-items: center;
  height: 7rem;
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;

  @include breakpoint(small down) {
    padding: 1.5rem 1.5rem;
    height: auto;
  }
}

.bcc-header-title {
  display: flex;
  font-size: 1.5rem;
  font-weight: normal;
  color: inherit;
  flex: 1 1 auto;

  @include breakpoint(small down) {
    font-size: 0.9375rem;
  }
}

.bcc-header-logo {
  flex: 0 0 auto;

  svg, img {
    width: 140px;
    height: auto;
    object-fit: contain;
  }
  @include breakpoint(small down) {
    display: block;
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);

    svg, img {
      width: 80px;
    }
  }
}

.bcc-header-back {
  color: $black;
  display: inline-flex;
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: bold;
  svg {
    fill: $black;
    margin-right: 0.25rem;
    @include breakpoint(small down) {
      top: -1px;
    }
  }
  &:hover {
    opacity: 0.8;
  }
}


.bcc-window-content {
  padding: 3rem;
  flex: 1 1 auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  @include breakpoint(small down) {
    padding: 2rem 1.5rem;
  }

  .bcc-ingress {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.3;
    @include breakpoint(small down) {
      font-size: 1rem;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 1.25rem 0 -0.5rem 0;
    @include breakpoint(small down) {
      font-size: 1rem;
    }

    li {

      display: flex;
      padding: 0.5rem 0;
      margin-left: 1.6rem;
      line-height: 1.6;
      font-size: 1rem;
      position: relative;
      @include breakpoint(small down) {
        padding: 0.4rem 0;
        line-height: 1.6;
        font-size: 0.875rem;
      }

      &::before {
        content: "";
        position: absolute;
        top: 11px;
        left: -1.6rem;
        display: inline-block;
        transform: rotate(45deg);
        height: 1em;
        width: .5em;
        border-radius: 2px;
        border-bottom: 4px solid grey;
        border-right: 4px solid grey;
      }
    }
  }
}

.bcc-check-list {
  list-style: none;
  padding: 0;
  margin: 1.25rem 0 -0.5rem 0;
  @include breakpoint(small down) {
    margin: 0.75rem 0 -0.5rem 0;
  }

  li {
    display: flex;
    padding: 0.5rem 0;
    line-height: 1.6;
    font-size: 1rem;
    @include breakpoint(small down) {
      padding: 0.4rem 0;
      line-height: 1.6;
      font-size: 0.875rem;
    }

    svg {
      fill: $light-grey;
      flex: 0 0 auto;
      position: relative;
      top: 5px;
      @include breakpoint(small down) {
        height: 12px;
      }
    }

    span {
      padding-left: 0.6rem;
      line-height: 1.6;
      @include breakpoint(small down) {
        padding-left: 0.5rem;
      }
    }
  }
}

.bcc-checkbox-container {
  margin-bottom: -1rem;
  margin-top: -1rem;

  @include breakpoint(small down) {
    margin-bottom: -0.75rem;
    margin-top: -0.75rem;
  }

  label {
    display: flex;
    cursor: pointer;
    padding: 0.75rem 1rem;
    width: calc(100% + 2rem);
    margin-left: -1rem;
    position: relative;

    &.disabled {
      cursor: default;
    }

    .bcc-checkbox-bg {
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-color: rgba(0,0,0,0.03);
      box-shadow: inset 0 0 0 1px rgba(0,0,0,0.03);
      opacity: 0;
      pointer-events: none;
    }
  }
}

.bcc-checkbox-input input:focus + .bcc-checkbox-bg  {
  opacity: 1;
}

.bcc-checkbox-input {
  flex: 0 0 auto;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  top: 2px;
  z-index: 1;
  @include breakpoint(small down) {
    width: 1.25rem;
    height: 1.25rem;
  }

  input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }
}


.bcc-checkbox-input-placeholder {
  width: 100%;
  height: 100%;
  border: 2px solid rgba(0,0,0,0.2);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  svg {
    opacity: 0;
    position: relative;
    left: 0px;
    fill: $black;
    @include breakpoint(small down) {
      height: 8px;
    }
  }
}


.bcc-checkbox-container label .bcc-checkbox-input input:focus + .bcc-checkbox-input-placeholder {
  box-shadow: 0 0 0 4px rgba(0,0,0,0.05);
  border-color: $light-grey;
}

@media (hover: hover) {
  .bcc-checkbox-container label:hover .bcc-checkbox-input input + .bcc-checkbox-input-placeholder svg {
    opacity: 0;
  }

  .bcc-checkbox-container label:hover .bcc-checkbox-input input + .bcc-checkbox-input-placeholder {
    border-color: rgba(0,0,0,0.4);
  }
}

.bcc-checkbox-input input:checked + .bcc-checkbox-input-placeholder svg {
  opacity: 1 !important;
}

.bcc-checkbox-container label .bcc-checkbox-input input:checked:disabled + .bcc-checkbox-input-placeholder {
  background-color: rgba(0,0,0,0.06);
  border-color: rgba(0,0,0,0.15);
}

.bcc-checkbox-container label .bcc-checkbox-input input:checked:disabled + .bcc-checkbox-input-placeholder svg {
  opacity: 0.4 !important;
  fill: $black;
}

.bcc-checkbox-text {
  padding-left: 0.75rem;
  font-size: 0.875rem;
  position: relative;
  z-index: 1;
  @include breakpoint(small down) {
    padding-left: 0.5rem;
  }
}

.bcc-checkbox-text-title {
  font-weight: bold;
  display: block;
  line-height: 1.6;
}

.bcc-checkbox-text-description {
  display: block;
  margin-top: 0rem;
  line-height: 1.6;
}

.bcc-window-footer {
  display: flex;
  padding: 2.5rem 3rem;
  background-color: #fff;
  box-shadow: 0 -1px 0 0 rgba(0,0,0,0.08);
  flex-shrink: 0;
  flex-grow: 0;
  @include breakpoint(small down) {
    padding: 0.5rem 1.5rem;
    display: block;
  }
}

.bcc-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.125rem;
  height: 56px;
  padding: 0 1.75rem;
  text-align: center;
  position: relative;
  cursor: pointer;
  @include breakpoint(small down) {
    height: 48px;
    padding: 0 1rem;
    font-size: 0.875rem;
    width: 100%;
    margin: 0.5rem 0;
  }

  span {
    position: relative;
    z-index: 1;
  }

  &:after {
    content:"";
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: rgba(0,0,0,0.15);
    opacity: 0;
  }
}

@media (hover: hover) {
  .bcc-button:hover:after {
    opacity: 1;
  }
}

.bcc-button-primary {
  background-color: $black;
  color: #fff;
  flex: 0 0 auto;
  margin-left: 1.5rem;
  padding: 0 3.5rem;
  @include breakpoint(small down) {
    margin-left: 0;
  }
}

.bcc-button-secondary {
  color: $black;
  font-weight: normal;
  background-color: rgba(0,0,0,0.05);
  box-shadow: inset 0 0 0 2px rgba(0,0,0,0.15);
  flex: 1 1 100%;
}

@media (hover: hover) {
  .bcc-button-secondary:hover:after {
    opacity: 0.25;
  }
}

.bcc-button-expanded {
  flex: 1 1 100%;
  margin-left: 0;
}

.bcc-cookie-tab {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0 0.55rem;
  background-color: $light-grey;
  z-index: 997;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bcc-cookie-tab a {
  color: $black;
  text-decoration: none;
  user-select: none;
  font-weight: normal;
  font-size: 0.875rem;
  font-family: inherit;
  margin:0;
  padding:0;
}


/* affectig all bcc styles */
.bcc-container * {  }

.bcc-container .bcc-window {
  overflow: hidden;
  box-shadow: 25px 25px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}


/* header title */
.bcc-header-title {
  font-size: 1.25rem;
  @include breakpoint(small down) {
    font-size: 0.8125rem;
  }
}


/* ingress text */
.bcc-window-content p.bcc-ingress {
  font-size: 1.125rem;
  @include breakpoint(small down) {
    font-size: 0.875rem;
  }
}


/* ul list */
.bcc-check-list li {
  font-size: 0.875rem; padding: 0.5rem 0; line-height: 1.6;
  @include breakpoint(small down) {
    font-size: 0.8125rem; padding: 0.4rem 0; line-height: 1.6;
  }
}


/* checkbox text */
.bcc-checkbox-text {
  font-size: 0.8125rem; padding-left: 0.75rem;
  @include breakpoint(small down) {
    font-size: 0.8125rem; padding-left: 0.5rem;
  }
}


/* header */
.bcc-window-header {
  /* background-color: #fff;
  color: $black;
  box-shadow: 0 1px 0 0 rgba(0,0,0,0.08); */
}


/* buttons */
.bcc-button {
  font-size: 1rem;  overflow: hidden;
}
@include breakpoint(small down) {
  .bcc-button {
    font-size: 0.8125rem;
  }
}


/* primary button */
.bcc-button-primary {
  background-color:$cookie-color;
}


/* secondary button */
.bcc-button-secondary {
  background-color: rgba($cookie-color,0.05);
  box-shadow: inset 0 0 0 2px rgba($cookie-color,0.2);
  &:hover {

  }
}


/* bold ingress text */
.bcc-ingress { color: $black; }


/* header back link */
.bcc-header-back {
  color: $cookie-color;
  svg {
    fill: $cookie-color;
  }
  @include breakpoint(small down) {
    font-size: 0.75rem; position: relative; top: 2px;
    svg {
      top: -1px; position: relative;
    }
  }
}


/* checkbox tick color */
.bcc-checkbox-input-placeholder {
  border-radius: 3px;
  svg {
    fill: $cookie-color;
  }
}


/* checkbox tick color */
.bcc-header-logo img {
  width: auto;
  height: 16px;
  position: relative;
  top: 2px;
  @include breakpoint(small down) {
    width: 86px;
    height: auto;
    position: relative;
    top: 0px;
  }
}


/* fixed right corner cookie tab link */
.bcc-cookie-tab {
  background-color: #fff;
  border-radius: 3px 0 0 0;
  a {
    /* color: $black; */
    font-weight: 600;
    font-size: 0.8125rem;
  }
}


/* bcc cookie details */
#bcc-show-cookie-details {
  display: inline-block;
  margin-top: 0.3rem;
  text-decoration: underline;
  opacity: 0.8;
  color: $black;
  font-weight: normal;
}

#bcc-show-cookie-details:hover {
  text-decoration: none;
  opacity: 1;
}

#bcc-show-cookie-details span:last-child,
#bcc-show-cookie-details.bcc-cookie-details-visible span:first-child {
  display: none;
}

#bcc-show-cookie-details.bcc-cookie-details-visible span:last-child {
  display: inline;
}

.bcc-cookie-details {
  line-height: 1.5;
  margin-top: 1rem;
  display: none;
  margin-bottom: 0.25rem;
}

.elc-cookie {
  background-color: rgba($black,0.04);
  padding: 0.8rem 1rem 0.55rem 1rem;
  position: relative;
  margin-bottom: 2px;
}

.elc-cookie-field {
  margin-bottom: 0.15rem;
  line-height: 1.5;
  font-size: 0.75rem;
}

.elc-cookie-field strong {
  font-weight: bold;
}

.bcc-cookie-details-visible + .bcc-cookie-details {
  display: block;
}
/* affectig all bcc styles */
.bcc-container * {  }


/* header title */
.bcc-header-title { font-size: 1.25rem; }
@media only screen and (max-width: 831px) {
  .bcc-header-title { font-size: 0.8125rem; }
}


/* ingress text */
.bcc-window-content p.bcc-ingress { font-size: 1.125rem; }
@media only screen and (max-width: 831px) {
  .bcc-window-content p.bcc-ingress { font-size: 0.875rem; }
}


/* ul list */
.bcc-check-list li { font-size: 0.875rem; padding: 0.5rem 0; line-height: 1.6; }
@media only screen and (max-width: 831px) {
  .bcc-check-list li { font-size: 0.8125rem; padding: 0.4rem 0; line-height: 1.6; }
}


/* checkbox text */
.bcc-checkbox-text { font-size: 0.8125rem; padding-left: 0.75rem; }
@media only screen and (max-width: 831px) {
  .bcc-checkbox-text { font-size: 0.8125rem; padding-left: 0.5rem; }
}


/* header */
.bcc-window-header { /* background-color: #fff; color: #000; box-shadow: 0 1px 0 0 rgba(0,0,0,0.08); */ }


/* buttons */
.bcc-button { font-size: 1rem; }
@media only screen and (max-width: 831px) {
  .bcc-button { font-size: 0.8125rem; }
}


/* primary button */
.bcc-button-primary { background-color: #326CB3; }


/* secondary button */
.bcc-button-secondary { background-color: rgba(47,52,126,0.05); box-shadow: inset 0 0 0 2px rgba(47,52,126,0.2); }
.bcc-button-secondary:hover {  }


/* bold ingress text */
.bcc-ingress { color: #000; }


/* header back link */
.bcc-header-back { color: #326CB3; }
.bcc-header-back svg { fill: #326CB3; }
@media only screen and (max-width: 831px) {
  .bcc-header-back { font-size: 0.75rem; position: relative; top: 2px; }
}
@media only screen and (max-width: 831px) {
  .bcc-header-back svg { top: -1px; position: relative; }
}


/* checkbox tick color */
.bcc-checkbox-input-placeholder svg { fill: #326CB3; }


/* checkbox tick color */
.bcc-header-logo img { width: auto; height: 25px; position: relative; top: 2px; }

@media only screen and (max-width: 831px) {
  .bcc-header-logo img { width: 80px; height: auto; position: relative; top: 2px; }
}


/* fixed right corner cookie tab link */
.bcc-cookie-tab { background-color: #F0F3F8; }
.bcc-cookie-tab a { /* color: #000; */ font-weight: 600; font-size: 0.8125rem; }
