#banner {
	background-color: $color-akastor-blue;
	color: #fff;
	padding: 110px 0;
	position: relative;
	background-image: url(/dist/images/logo-symbol.svg);
	background-repeat: no-repeat;
	background-position: left 70px top 70px;
	background-size: 700px;

	@include breakpoint(medium down) {
		padding: 1rem 0 40px 0;
		background-position: left 20px top 400px;
		background-size: 90%;

		.grid-x {
			flex-direction: column-reverse;
		}
	}

	a {
		color: #fff;
	}

	.background {
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 50%;
		background-position: center center;
		background-size: cover;
		display: flex;
		align-items: center;
		justify-content: center;

		@include breakpoint(medium down) {
			position: static;
			height: 500px;
			margin-bottom: 2rem;
		}

		@include breakpoint(small down) {
			position: static;
			height: 300px;
			margin-bottom: 2rem;
		}

		a {
			display: block;
			background: rgba($color-akastor-blue, 0.9);
			margin: 0;
			border: 0;
			box-shadow: 0 2px 20px 0 rgba(0, 0, 0, .6);
			font-size: 30px;
			transition: 400ms $transition;
			transform: scale(1);
			padding: 20px 30px;
			max-width: 90%;

			@include breakpoint(730px down) {
				font-size: 24px;
				padding: 15px 25px;
			}

			@include breakpoint(500px down) {
				font-size: 18px;
				padding: 10px 15px;
			}

			&:hover {
				transform: scale(1.025);
			}
		}
	}

	h1 {
		font-family: "Work Sans";
		font-size: 45px;
		font-weight: 300;
		line-height: 52px;
		margin: 0 0 1rem 0;
	}

	p {
		font-family: "Work Sans";
		font-size: 18px;
		font-weight: 300;
		line-height: 30px;
		max-width: 512px;
	}
}

#pages {
	background: #fff;
	padding: 110px 0;

	@include breakpoint(medium down) {
		padding: 2rem 0 0 0;

		.cell {
			margin-bottom: 30px;
		}
	}

	.page {
		background-position: center center;
		background-size: cover;
		min-height: 300px;
		position: relative;

		a {
			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;
			background: rgba(#285E9F, 0.68);

			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			text-decoration: none;
			font-family: "Work Sans";
			font-size: 28px;
			font-weight: 600;
			line-height: 33px;
			text-shadow: 0 2px 23px 0 rgba(0,0,0,0.35);
		}
	}
}

.ticker {
	padding: 43px 48px;
	background: #F0F3F8;

	h2 {
		font-family: "Work Sans";
		font-size: 22px;
		font-weight: 300;
		line-height: 25px;
		margin-top: 0;
	}

	label {
		display: block;
		opacity: 0.6;
		font-family: "Work Sans";
		font-size: 14px;
		line-height: 16px;
		margin-bottom: 5px;
	}

	span {
			font-family: "Work Sans";	font-size: 32px;	font-weight: 300;	line-height: 38px;
			display: inline-block;
	}

	.cell {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-bottom: 20px;
	}

	.last {
		color: $color-akastor-blue;
		font-weight: normal;
		font-size: 48px;
	}

	.change {
		display: flex;
		flex-direction: row;
		align-items: center;

		img {
			margin-right: 10px;
		}
	}

	p {
		font-family: "Work Sans";
		font-size: 13px;
		line-height: 18px;
		margin-bottom: 0;

		small {
			color: rgba(0,0,0,0.5);
			font-size: 11px;
		}
	}
}

#info {
	padding: 110px 0;

	@include breakpoint(medium down) {
		padding: 2rem 0 0 0;

		.cell {
			margin-bottom: 30px;
		}
	}

	.cell {
		background: #fff;
		padding: 43px 48px;
		display: flex;
		flex-direction: column;

		h2 {
			font-family: "Work Sans";
			font-size: 22px;
			font-weight: 300;
			line-height: 25px;
			text-transform: uppercase;
		}
	}

	article {
		margin-bottom: 20px;

		&.small-margin {
			margin-bottom: 10px;
		}

		&.no-margin {
			margin: 0;
			margin-top: 10px;
		}

		label {
			display: block;
			color: rgba(#000, 0.4);
			font-family: "Work Sans";
			font-size: 12px;
			font-weight: 600;
			line-height: 24px;
		}

		span {
			font-size: 16px;	font-weight: 300;	line-height: 24px;
		}
	}
}

a {
	color: #326CB3;	font-family: "Work Sans";	font-weight: 300;	line-height: 24px;

	&:hover {
		text-decoration: none;
		color: darken(#326CB3, 10%);
	}
}

.btn {
	display: inline-flex;
	align-items: center;
	padding: 14px;
	color: $color-akastor-blue;
	border: 1px solid rgba($color-akastor-blue, 0.3);
	border-radius: 2px;
	background: transparent;
	transition: all 400ms $transition;
	text-decoration: none;
	margin-top: 30px;
	font-size: 16px;
	line-height: 1;

	&:hover {
		background: rgba($color-akastor-blue, 0.2);
	}

	&.light {
		color: #fff;
		border: 1px solid rgba(#fff, 0.3);

		&:hover {
			background: rgba(#fff, 0.3);
		}
	}
}