.news-article-hero {
    width: 100%;
    padding: 4.5rem 0;
    background-image: radial-gradient(circle at 50% 50%, #FFFFFF 15%, #EBF0F6 60%);
    position: relative;


    @include breakpoint(medium down) {
        background-image: radial-gradient(circle at 50% 50%, #FFFFFF 15%, #E5EBF2 60%);
    }

    @include breakpoint(medium) {
        padding: clamp(5rem, 10vw, 9rem) 0;
    }


    &.hero-image {
        padding-bottom: 0;
    }

    .grid-container {
        position: relative;
        z-index: 1;
    }

    .news-article-date {
        font-weight: 600;
        font-size: 14px;
        color: #868686;
        display: inline-block;
        margin-bottom: 1em;

        @include breakpoint(medium) {
            font-size: 17px;
        }
    }

    h1 {
        font-weight: normal;
        margin: 0;
        font-size: clamp(24px, 2.5vw, 36px);
        line-height: 1.3;

        @include breakpoint(medium down) {
            font-size: 32px; 
        }

        @include breakpoint(small down) {
            font-size: 24px;
        }

        strong {
            font-weight: 600;
        }
    }
    
    .news-article-image {
        margin-top: 2.25rem;
        width: 100%;
        padding-bottom: 62.5%; /* 16:10 aspect ratio */
        position: relative;

        @include breakpoint(medium) {
            margin-top: 3rem;
        }

        @include breakpoint(large) {
            margin-top: 4rem;
        }

        img {
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            z-index: 1;
        }

        &:after {
            content:"";
            width: 1000%;
            background-color: #F6F8FB;
            position: absolute;
            left: -400%;
            bottom: 0;
            height: 50%;
            z-index: 0;
        }

    }

    .news-article-symbol-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;
    }

    .news-article-symbol {
        width: 100%;
        height: 100%;
        background: transparent url(/dist/images/logo-symbol-dark.svg) center center no-repeat;
        background-size: contain;
        opacity: 0.75;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-40%) scale(1.75);
    }

    &.hero-image { 

        background-image: radial-gradient(circle at 50% 50%, #FFFFFF 15%, #EBF0F6 80%);

        @include breakpoint(medium down) {
            background-image: radial-gradient(circle at 50% 50%, #FFFFFF 15%, #E5EBF2 80%);
        }

        .news-article-symbol-container {
            height: 80%;

            .news-article-symbol {
                transform: translateY(-50%) translateX(-35%) scale(1.5);
            }
        }
    }
}

.news-article-body {

    width: 100%;
    padding: 2.5rem 0;

    @include breakpoint(medium) {
        padding: clamp(5rem, 10vw, 8rem) 0; 
    }


    ul, li, p {
        font-size: 16px !important;
        font-family: "Work Sans" !important;
        font-weight: normal !important;
        line-height: 1.65 !important;

        @include breakpoint(medium) {
            font-size: 19px !important; 
        }
    }

    ul {
        margin-top: 0 !important;

        li {
            margin: 0 0 0.75rem 0 !important;
            &:last-of-type {
                margin-bottom: 0 !important;
            }
        }
    }

    h2,h3,h4,h5,h6 {
        font-weight: 600;
    }

    h2 {
        @include breakpoint(small down) {
            font-size: 1.3rem;
        }
    }

    .person h4 {
        font-weight: 300;
    }

    .person {
        margin-top: 0.5rem;
    }

    ul, p {
        margin-bottom: 32px !important;

        @include breakpoint(medium) {
            margin-bottom: 44px !important; 
        }
    }

    p.ingress {
        font-size: 19px !important;

        @include breakpoint(medium) {
            font-size: 21px !important; 
        }
    }

    p strong {
        font-weight: 600;
    }

    ul.files {
        list-style: none;   
        margin-top: -0.25rem !important;
        padding: 0 !important;
        
        li {
            margin: 0 0 0.75rem 0 !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 17px !important;

            @include breakpoint(medium down) {
                font-size: 16px !important;
            }

            @include breakpoint(small down) {
                font-size: 13px !important;
            }

            a {
                .file-type {

                    padding: 3px 3px 3px 3px;
                    font-size: 11px;
                    line-height: 11px;
                    position: relative;
                    top: -1px;
                    margin-right: 6px;

                    @include breakpoint(medium) {
                        padding: 6px 4px 5px 4px;
                        font-size: 12px;
                        top: -2px;
                        margin-right: 10px;
                    }

                    &:after {
                        content: attr(data-file-type);
                    }
                }
            }
        }
    }

    a {
        color: darken(#326CB3, 5%);
        font-weight: 400;
    
        &:hover {
            text-decoration: none;
            color: lighten(#326CB3, 5%)
        }
    }
}

.news-article-hero.hero-image + .news-article-body  {
    padding-top: 1.25rem;

    @include breakpoint(medium) {
        padding-top: 4rem;
    }
}