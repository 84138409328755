@import 'https://fonts.googleapis.com/css?family=Work+Sans:300,400,600,700';
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

/* Foundaton */
$global-width: 1440px;
$grid-margin-gutters: 32px;
$white: #fff;
/* Custom */
$nav-height: 86px;
$nav-height-mobile: 50px;
$transition: cubic-bezier(.25,.8,.25,1);

$color-akastor-blue: #326CB3;
$color-background-content: #F6F8FB;
$color-background: #e4e8f0;

$breakpoint-classes: (small medium large xlarge xxlarge);